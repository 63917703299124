import { Injectable } from '@angular/core';
import { User } from '@app/core/models/user.model';
import { neoLinkBaseUrl } from '@app/core/settings/app.config';
import { AuthenticationService } from '@app/services/authentication.service';
import { QueryParams } from '@app/shared/components/neo-table/neo-table-value';
import urlcat from 'urlcat';
import { ClientQueryParams } from '../models';
import { DiaryEntriesQueryParams } from '../models/diary-entries.model';
import { FormDropdownQueryParams } from '../models/form-submission.model';
import { HolidaysQueryParams } from '../models/holidays.model';
import { ManagementUsersQueryParams, ProjectQueryParams } from '../models/project.model';
import { LeaveTypeQueryParams, StaffQueryParams } from '../models/staff.model';
import { XeroAuthorizationQueryParams } from '../models/xero.model';

@Injectable({
  providedIn: 'root',
})
export class UrlProviderService {
  staffPageUrl: string = '';
  clientPageUrl: string = '';
  projectCalenderPageUrl: string = '';
  equipmentCalenderPageUrl: string = '';
  plantRegisterPageUrl: string = '';
  equipmentDefectPageUrl: string = '';
  timeSheetPageUrl: string = '';
  ohseFormPageUrl: string = '';
  siteIncidentPageUrl: string = '';
  invoicingPageUrl: string = '';
  formsReportingPageUrl: string = '';
  logsheetsWaitingApprovalPageUrl: string = '';
  siteDrawingsPhotosPageUrl: string = '';
  labourReportPageUrl: string = '';
  purchaseOrderReportPageUrl: string = '';
  factorySpendReportPageUrl: string = '';
  productivityReportPageUrl: string = '';
  subcontractorReportPageUrl: string = '';
  materialReportPageUrl: string = '';
  configurationsPageUrl: string = '';
  smsTemplatePageUrl: string = '';
  bookingStatusReportPageUrl: string = '';
  dynamicTimeFieldsPageUrl: string = '';
  emailTemplatePageUrl: string = '';
  projectStatusPageUrl: string = '';
  settingsPageUrl: string = '';
  formBuilderPageUrl: string = '';
  user: User | null;

  constructor(private authenticationService: AuthenticationService) {
    this.user = this.authenticationService.getActiveUser();
    if (this.user) {
      this.staffPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'Staff/Index'
      );
      this.clientPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'Clients/Index'
      );
      this.projectCalenderPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'Calendar'
      );
      this.equipmentCalenderPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'CalendarEquipment'
      );
      this.plantRegisterPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'Equipment/Index'
      );
      this.equipmentDefectPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'reporting/EquipmentDefects/Index'
      );
      this.timeSheetPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'reporting/Timesheets/Index'
      );
      this.ohseFormPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'reporting/OHSEForms/Index'
      );
      this.siteIncidentPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'reporting/SiteIncidents/Index'
      );
      this.invoicingPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'reporting/Invoicing/Index'
      );
      this.formsReportingPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'reporting/FormsReports/Index'
      );
      this.logsheetsWaitingApprovalPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'reporting/LogsheetsApproval/Index'
      );
      this.siteDrawingsPhotosPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'reporting/Sections/Index'
      );
      this.labourReportPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'Labour/Index'
      );
      this.purchaseOrderReportPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'POReport/Index'
      );
      this.factorySpendReportPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'FactorySpendReport/Index'
      );
      this.productivityReportPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'ProductivityReport/Index'
      );
      this.subcontractorReportPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'SubcontractorReport/Index'
      );
      this.materialReportPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'MaterialReport/Index'
      );
      this.configurationsPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'Templates/Index'
      );
      this.smsTemplatePageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'Templates/SMSTemplates'
      );
      this.bookingStatusReportPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'StatusMaster/StatusMaster'
      );
      this.dynamicTimeFieldsPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'DynamicTimeField'
      );
      this.emailTemplatePageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'EmailTemplate/EmailTemplate'
      );
      this.projectStatusPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'ProjectStatus'
      );
      this.settingsPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'Settings'
      );
      this.formBuilderPageUrl = urlcat(
        this.user.extensionLegacyNeoSiteUrl,
        'CustomBuilder'
      );
    }
  }

  getLegacyAddNewStaffPageUrl = (): string => {
    return urlcat(this.user?.extensionLegacyNeoSiteUrl || '', '/Staff/Create');
  }

  getDailyLogFormEditUrl = (redirectUrl: string): string => {
    return urlcat(this.user?.extensionLegacyNeoSiteUrl || '', redirectUrl);
  }

  getLegacyAddNewClientPageUrl = (): string => {
    return urlcat(this.user?.extensionLegacyNeoSiteUrl || '', '/Clients/Create');
  }
}

export const getClientProjectsURL = function (clientId: number): string {
  return urlcat(neoLinkBaseUrl, 'Clients/:clientId/projects', { clientId });
};

export const getClientSummaryURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'Clients/summary');
};

export const getClientListURL = function (params: ClientQueryParams): string {
  return urlcat(neoLinkBaseUrl, 'Clients', params);
};

export const getProjectListURL = function (params: ProjectQueryParams): string {
  return urlcat(neoLinkBaseUrl, 'Projects', params);
};

export const getStaffListURL = function (params: StaffQueryParams): string {
  return urlcat(neoLinkBaseUrl, 'Staffs', params);
};

export const getLeavesTypeListURL = function (params: LeaveTypeQueryParams): string {
  return urlcat(neoLinkBaseUrl, 'PayrollSettings/LeaveTypes', params);
};

export const getAddLeavesURl = function (staffId: string): string {
  return urlcat(neoLinkBaseUrl, 'Staffs/:staffId/Leaves/Add', { staffId });
};

export const updateStaffStatusURL = function (staffId: string, isActive: boolean): string {
  return urlcat(neoLinkBaseUrl, 'Staffs/:staffId/updateStaffStatus', { staffId, isActive });
}

export const getMangementUserListURL = function (params: ManagementUsersQueryParams): string {
  return urlcat(neoLinkBaseUrl, 'Users/managementUsers', params);
};

export const getPendingTimesheetURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'clientContact/timesheets/pendingForApproval');
};

export const getApprovedTimesheetURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'clientContact/timesheets/approved');
};

export const getRejectedTimesheetURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'clientContact/timesheets/rejected');
};

export const timesheetApprovalSignatureURL = function (): string {
  return urlcat(
    neoLinkBaseUrl,
    'clientContact/timesheets/approvalSignature'
  );
};

export const timesheetapprovalsURL = function (): string {
  return urlcat(
    neoLinkBaseUrl,
    'clientContact/timesheets/approvals'
  );
};

export const assignUser = function (
  projectId: number
): string {
  return urlcat(
    neoLinkBaseUrl,
    'Projects/:projectId/AssignUser',
    { projectId }
  );
};

export const unassignUser = function (
  projectId: number
): string {
  return urlcat(
    neoLinkBaseUrl,
    'Projects/:projectId/UnassignUser',
    { projectId }
  );
};

export const getScheduledBudgetsSummaryURL = function (
  projectId: number
): string {
  return urlcat(
    neoLinkBaseUrl,
    'Projects/:projectId/scheduledBudgets/Summary',
    { projectId }
  );
};

export const getSettingsScheduleWorks = function (): string {
  return urlcat(neoLinkBaseUrl, 'Setttings/ScheduledWorks');
};

export const addManyWorkPerformBudget = function (projectId: number): string {
  return urlcat(
    neoLinkBaseUrl,
    'Projects/:projectId/scheduledBudgets/AddMany',
    { projectId }
  );
};

export const updateManyWorkPerformBudget = function (
  projectId: number
): string {
  return urlcat(
    neoLinkBaseUrl,
    'Projects/:projectId/scheduledBudgets/UpdateMany',
    { projectId }
  );
};

export const deleteWorkPerformBudget = function (
  projectId: number,
  workPerformedBudgetId: number
): string {
  return urlcat(
    neoLinkBaseUrl,
    'Projects/:projectId/scheduledBudgets/Delete/:workPerformedBudgetId',
    { projectId, workPerformedBudgetId }
  );
};

export const deleteWorkPerformBudgets = function (projectId: number): string {
  return urlcat(
    neoLinkBaseUrl,
    'Projects/:projectId/scheduledBudgets/DeleteMany',
    { projectId }
  );
};

export const getPowerBiEmbededConfigUrl = function (
  reportId: string,
  workspaceId: string
): string {
  return urlcat(neoLinkBaseUrl, 'Settings/PowerBiEmbededConfig', {
    reportId,
    workspaceId,
  });
};

export const assignProjectsURL = function (
  staffId: string
): string {
  return urlcat(
    neoLinkBaseUrl,
    'Staffs/:staffId/AssignProjects',
    { staffId }
  );
};

export const unassignProjectsURL = function (
  staffId: string
): string {
  return urlcat(
    neoLinkBaseUrl,
    'Staffs/:staffId/UnAssignProjects',
    { staffId }
  );
};

export const getCaledarSettingsUrl = function (): string {
  return urlcat(neoLinkBaseUrl, 'Settings/calendar/getCalendarSettings');
};

export const saveCaledarSettingsUrl = function (): string {
  return urlcat(neoLinkBaseUrl, 'Settings/calendar/saveCalendarSettings');
};

export const getCaledarSettingsSmsGapDaysUrl = function (): string {
  return urlcat(neoLinkBaseUrl, 'Settings/calendar/smsGapDays');
};

export const getJobpacSettingsURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'Settings/jobpac');
};

export const getJobpacImportClientsAndProjectsURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'import/clientsAndProjectsFromJobpac');
}

export const getImportHistoryURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'import/history');
}

export const getJobpacImportStaffURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'import/staffFromJobpac');
}

export const getDiaryEntriesURL = function (params: DiaryEntriesQueryParams): string {
  return urlcat(neoLinkBaseUrl, 'SiteDiary', params);
}

export const getDiaryEntriesClientsURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'SiteDiary/clients');
}

export const getDiaryEntriesProjectsURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'SiteDiary/projects');
}

export const getDiaryEntriesSectionsURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'SiteDiary/sections');
}

export const getDiaryEntriesAreasURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'SiteDiary/areas');
}

export const getProjectImageThumbnailURL = function (projectPhotoId: number): string {
  return urlcat(neoLinkBaseUrl, 'Files/ProjectImageThumbnail', { projectPhotoId });
}

export const getProjectStatusURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'Projects/projectStatus');
}

export const getFormTemplateURL = function (formTemplateId: number): string {
  return urlcat(neoLinkBaseUrl, 'formTemplates/:formTemplateId', { formTemplateId });
};

export const getFormSubmissionURL = function (formSubmissionId: number): string {
  return urlcat(neoLinkBaseUrl, 'formSubmissions/details/:formSubmissionId', { formSubmissionId });
};

export const getFormDropdownURL = function (formDropdownQueryParams: FormDropdownQueryParams): string {
  return urlcat(neoLinkBaseUrl, 'forms/dropdowns/:dropdownId/:formSubmissionId/values', formDropdownQueryParams);
};

export const updateFormFieldsValuesURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'formSubmissions/fieldValue/update');
};

export const newRepeatableSectionRowURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'formSubmissions/fieldValue/repeatableSection');
};

export const deleteRowURL = function (formSubmissionId: number, repeatableSectionId: number, groupId: number): string {
  return urlcat(neoLinkBaseUrl, 'formSubmissions/fieldValue/repeatableSection/:formSubmissionId/:repeatableSectionId/:groupId',
    { formSubmissionId, repeatableSectionId, groupId });
};

export const addFormSubmissionPhotosURL = function (formSubmissionId: number, formFieldId: number): string {
  return urlcat(neoLinkBaseUrl, 'formSubmissions/fieldValue/updatePhoto', { formSubmissionId, formFieldId });
};

export const deleteFormSubmissionPhotosURL = function (formSubmissionId: number, dailyLogSheetFormPhotoId: number): string {
  return urlcat(neoLinkBaseUrl, 'formSubmissions/fieldValue/deletePhoto/:formSubmissionId/:dailyLogSheetFormPhotoId', { formSubmissionId, dailyLogSheetFormPhotoId });
};

export const formSubmissionSignatureURL = function (formSubmissionId: number, formFieldId: number): string {
  return urlcat(neoLinkBaseUrl, 'formSubmissions/fieldValue/signature', { formSubmissionId, formFieldId });
};

export const closeFormSubmissionURL = function (formSubmissionId: number): string {
  return urlcat(neoLinkBaseUrl, 'formSubmissions/close/:formSubmissionId', { formSubmissionId });
};

export const approveFormSubmissionURL = function (formSubmissionId: number): string {
  return urlcat(neoLinkBaseUrl, 'formSubmissions/approve/:formSubmissionId', { formSubmissionId });
};

export const getAllNotificationTypesURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'Notifications/shiftAllocation/notificationTypes');
};

export const getConfiguredNotificationTypesURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'Settings/shiftAllocation/notificationType');
};

export const sendMailSubmissionURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'formSubmissions/email');
};

export const getOrganizationFeatureFlagsURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'Configuration/OrganizationFeatureFlags');
};

export const getOrganizationAppConfigurationURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'Configuration/OrganizationAppConfiguration');
};

export const getTimesheetExportHistoryURL = function (queryParams: QueryParams): string {
  return urlcat(neoLinkBaseUrl, 'ExportHistory', queryParams);
};

export const getTimesheetExportHistoryDetailURL = function (exportHistoryId: number): string {
  return urlcat(neoLinkBaseUrl, 'ExportHistory/:exportHistoryId', { exportHistoryId });
};

export const getCalendarBookingsURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'CalendarBooking/Search');
};

export const getCalendarInitialDataURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'CalendarBooking/Initialise');
};

export const getCustomTimesheetFieldsURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'Timesheets/TimesheetFields');
}

export const getTimesheetReportURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'Timesheets/Search');
};

export const getDownloadTimesheetURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'Timesheets/ExportAsCSV');
}

export const getDownloadTimesheetExportPayrollURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'PayrollSchedule/DownloadPayrollScheduleRecordsFile');
}

export const getCreatePayrollScheduleURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'PayrollSchedule/CreatePayrollSchedule');
}

export const getPayrollSchedulesURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'PayrollSchedule/Search');
}

export const getPayrollScheduleDetailURL = function (payrollScheduleId: number): string {
  return urlcat(neoLinkBaseUrl, 'PayrollSchedule/GetPayrollSchedule/:payrollScheduleId', { payrollScheduleId });
};

export const getRunPayrollScheduleURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'PayrollSchedule/RunPayrollSchedule');
};

export const getCheckCalendarProjectBookingURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'CalendarBooking/CheckCalendarBooking');
};

export const getCreateCalendarProjectBookingURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'CalendarBooking/CreateCalendarBooking');
};

export const getUpdateCalendarProjectBookingURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'CalendarBooking/UpdateCalendarBooking');
};

export const getDeleteCalendarBookingsURL = function (projectId: number): string {
  return urlcat(
    neoLinkBaseUrl,
    'CalendarBooking/DeleteCalendarBooking/:projectId',
    { projectId });
};

export const getSendCalendarBookingSmsNotificationsURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'CalendarBooking/SendCalendarBookingSmsNotifications');
};

export const getUpdateCalendarBookingStatusURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'CalendarBooking/UpdateCalendarBookingStatus');
};

export const getSetCalendarDayLockURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'CalendarBooking/SetCalendarDayLock');
};

export const getXeroAuthorizationURL = function (queryParams: XeroAuthorizationQueryParams): string {
  return urlcat(neoLinkBaseUrl, 'Xero/Authorize', queryParams);
};

export const getXeroConnectAuthorizeURL = function (returnUrl: string): string {
  return urlcat(neoLinkBaseUrl, 'Xero/Connect', {returnUrl});
};

export const getHolidaysListURL = function(queryParams: HolidaysQueryParams): string {
  return urlcat(neoLinkBaseUrl, 'Settings/holiday/list');
};

export const saveHolidaysURL = function(): string {
  return urlcat(neoLinkBaseUrl, 'Settings/holiday/');
};

export const deleteHolidaysURL = function(id: number): string {
  return urlcat(neoLinkBaseUrl, 'Settings/holiday/', {holidayId: id});
};

export const getPayrollScheduleFileSettingsURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'Settings/PayrollSchedule/getPayrollScheduleFileSettings');
};

export const savePayrollScheduleFileSettingsURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'Settings/PayrollSchedule/savePayrollScheduleFileSettings');
};

export const getDynamicQuerySettingsURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'Setttings/payrollSchedule/getPayrollScheduleDynamicQuerySettings');
};

export const saveDynamicQuerySettingsURL = function (): string {
  return urlcat(neoLinkBaseUrl, 'Setttings/payrollSchedule/savePayrollScheduleDynamicQuerySettings');
};

export const getRemoveCalendarBookingEquipmentURL = function(calendarBookingId: number, equipmentId: number): string {
  return urlcat(neoLinkBaseUrl, 'CalendarBooking/:calendarBookingId/RemoveCalendarBookingEquipment/:equipmentId', { calendarBookingId, equipmentId });
}

export const getRemoveCalendarBookingStaffURL = function(calendarBookingId: number, staffId: string): string {
  return urlcat(neoLinkBaseUrl, 'CalendarBooking/:calendarBookingId/RemoveCalendarBookingStaff/:staffId', { calendarBookingId, staffId });
}

export const getAssignCalendarBookingEquipmentURL = function(calendarBookingId: number): string {
  return urlcat(neoLinkBaseUrl, 'CalendarBooking/:calendarBookingId/AssignCalendarBookingEquipment', { calendarBookingId });
}

export const getAssignCalendarBookingStaffURL = function(calendarBookingId: number): string {
  return urlcat(neoLinkBaseUrl, 'CalendarBooking/:calendarBookingId/AssignCalendarBookingStaff', { calendarBookingId });
}

export const getConfirmCalendarBookingURL = function(calendarBookingId: number): string {
  return urlcat(neoLinkBaseUrl, 'CalendarBooking/:calendarBookingId/confirm', { calendarBookingId });
}

export const getUnconfirmCalendarBookingURL = function(calendarBookingId: number): string {
  return urlcat(neoLinkBaseUrl, 'CalendarBooking/:calendarBookingId/unconfirm', { calendarBookingId });
}

export const getApproveCalendarBookingURL = function(calendarBookingId: number): string {
  return urlcat(neoLinkBaseUrl, 'CalendarBooking/:calendarBookingId/approve', { calendarBookingId });
}

export const getUnapproveCalendarBookingURL = function(calendarBookingId: number): string {
  return urlcat(neoLinkBaseUrl, 'CalendarBooking/:calendarBookingId/unapprove', { calendarBookingId });
}

export const getRejectCalendarBookingURL = function(calendarBookingId: number): string {
  return urlcat(neoLinkBaseUrl, 'CalendarBooking/:calendarBookingId/reject', { calendarBookingId });
}

